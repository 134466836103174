@import "../../index.scss";

.header-section {
  height: 100vh;
  background: $background-colours;
  position: relative;

  .robot {
    width: max(34vw, 65vh);
    position: absolute;
    bottom: 0px;
    right: 33%;

    @media (max-width: 480px) {
      height: 65vh;
      width: auto;
      right: 25vw;
    }

    @media (max-height: 720px) and (max-width: 480px) {
      right: 40vw;
    }
  }

  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: clamp(1rem, 7%, 8rem);
    padding-top: clamp(1rem, 7%, 8rem);
    padding-bottom: 1rem;
    padding-right: 1rem;
    margin: auto;
    height: 100vh;

    @media (max-width: 480px) {
      flex-direction: column;
      justify-content: space-between;
    }

    .left-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-self: flex-start;

      h1 {
        font-size: clamp(4rem, 5.3vw, 5rem);
        margin-top: 2rem;
        color: black;
        text-shadow: 1px 1px 2px gray;

        @media (max-width: 480px) {
          margin-top: 3rem;
          width: 8ch;
          margin-bottom: 0px;
          font-size: 6.5vh;
        }
      }

      button {
        text-transform: uppercase;
        margin-left: 10%;
        width: 150px;
        padding: 1rem 10px;
        border-radius: 20px;
        border-color: $copper-red;
        font-size: 14px;
        background-color: $copper-red;
        border-style: solid;
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
          rgba(0, 0, 0, 0.23) 0px 6px 6px;

        @media (max-width: 840px) {
          display: none;
        }
      }
      button:hover {
        transform: scale(1.04);
        transition-duration: 0.2s;
      }
    }

    .right-container {
      padding-top: 8rem;
      width: 45%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      color: black;
      position: relative;

      @media (max-width: 480px) {
        margin-bottom: 1vh;
        padding-top: 0rem;
      }

      .big-bubble {
        background: radial-gradient(
          circle,
          $copper-red 23%,
          $copper-red-dark 100%
        );
        border-radius: 50%;
        width: 300px;
        height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        position: relative;

        @media (max-width: 480px) {
          height: 76vw;
          width: 76vw;
          margin-top: 3rem;
        }

        @media (max-width: 480px) and (max-height: 680px) {
          height: 72vw;
          width: 72vw;
        }

        h2 {
          text-align: center;
          margin-top: 1rem;
          margin-bottom: 0.6rem;
          font-size: 20px;
          font-weight: 400;

          @media (max-width: 976px) {
            margin-top: 0.4rem;
            width: 13ch;
            font-size: 3.2vh;
            padding-left: 10px;
            padding-right: 10px;
            padding-bottom: 0.4rem;
            margin-left: 1.2rem;
            margin-right: 1.2rem;
            margin-bottom: 0px;
          }
        }

        span {
          font-weight: bold;
          font-size: 24px;
          margin-top: 0.5rem;

          @media (max-width: 976px) {
            font-size: 1.8rem;
            margin-top: 5px;
          }
        }

        p {
          font-size: 0.9rem;
          margin-bottom: 5px;
          color: black;
          font-weight: bold;
          @media (max-width: 976px) {
            margin-top: 0.7rem;
          }
        }

        .btn-group {
          @media (max-width: 976px) {
            display: flex;
            align-items: center;
          }
          button {
            width: 2.5rem;
            height: 2.5rem;
            margin: 0.4rem;
            margin-bottom: 0px;
            border-radius: 50%;
            background: $copper-red-light;
            border: $copper-red-light;
            color: hsl(0, 0%, 93%);
            font-size: 1.2rem;
            font-weight: bold;
            border-style: solid;
            text-shadow: 1px 1px 2px black;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
              rgba(0, 0, 0, 0.23) 0px 3px 6px;
            &:hover {
              background: hsl(14, 53%, 27%);
              border: hsl(14, 53%, 27%);
              border-style: solid;
            }
            @media (max-width: 976px) {
              border-radius: 50%;
              font-size: 0.6rem;
              height: 35px;
              width: 35px;
              padding: 0px;
            }
          }
          .active {
            background: hsl(14, 53%, 27%);
            border: hsl(14, 53%, 27%);
            border-style: solid;
          }
        }

        .small-bubble {
          position: absolute;
          top: -8.5rem;
          right: -2.5rem;
          background: radial-gradient(
            circle,
            hsl(0, 9%, 98%),
            rgb(219, 218, 218) 100%
          );
          border-radius: 50%;
          width: 200px;
          height: 200px;
          text-align: center;
          align-self: flex-end;
          color: black;
          padding: 1rem;

          @media (max-width: 976px) {
            width: 150px;
            height: 150px;
            top: -6rem;
            right: -1.5rem;
            span:last-of-type {
              font-size: 01.2rem;
            }
          }

          .material-icons {
            font-size: 50px;

            @media (max-width: 976px) {
              font-size: 1.8rem;
              margin-top: 0px;
              margin-bottom: 0px;
            }
          }

          h2 {
            margin: 0.4rem 0rem;

            @media (max-width: 976px) {
              padding: 0px;
              padding-bottom: 6px;
              font-size: 1rem;
              margin-top: 0px;
              margin-bottom: 0px;
            }
          }
        }
      }
    }
  }
}
