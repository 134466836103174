@import "../index.scss";

.about-page {
  background: $background-colours;
  min-height: 100vh;
  padding: 3rem 4rem;
  position: relative;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;

  .page-wrapper {
    padding-bottom: 21rem;

    @media (max-width: 480px) {
      padding-bottom: 2rem;
    }
  }

  @media (max-width: 480px) {
    padding: 4rem 1.5rem;
  }

  .light-bulb {
    width: 300px;
    opacity: 0.85;
    position: absolute;
    top: 0px;
    right: 38px;
    filter: blur(0.6px);
    display: none;

    @media (max-width: 976px) {
      right: -30px;
    }
    @media (max-width: 480px) {
      top: -4rem;
      width: 200px;
      right: 20vw;
      display: none;
    }
  }
  .robot {
    width: 400px;
    position: absolute;
    bottom: 0px;
    left: -44px;

    @media (max-width: 480px) {
      left: -5vw;
      bottom: -285px;
      width: 400px;
      transform: rotate(-30deg);
    }
  }
  .content-container {
    // display: flex;
    // justify-content: center;
    // padding-top: 2rem;
    // max-width: 70vw;
    // margin-left: auto;
    // margin-right: auto;

    // @media (max-width: 480px) {
    //   padding-top: 0px;
    //   max-width: 100%;
    // }

    .contact-p {
      font-weight: bold;
      font-size: 14px;
      color: $copper-red;
    }

    div {
      line-height: 1.4;
      padding-right: 1rem;

      @media (max-width: 480px) {
        padding: 0px;
        margin-bottom: 2rem;
      }

      h2 {
        border-width: 0px 0px 3px 0px;
        border-style: solid;
        border-color: $copper-red;
        width: fit-content;
      }
    }
  }
}
