$copper-red: hsl(14, 62%, 53%);
$copper-red-light: hsl(14, 62%, 60%);
$copper-red-dark: hsl(14, 62%, 35%);
$brown-dark: hsl(17, 12%, 23%);
$silver: hsl(0, 0%, 77%);
$black: hsl(17, 13%, 11%);
$umber: hsl(22, 17%, 30%);
$background-colours: radial-gradient(rgb(233, 233, 233), rgb(190, 190, 190));

* {
  box-sizing: border-box;
}

html,
body {
  font-family: "Comfortaa", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue";
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;

  &:focus-within {
    scroll-behavior: smooth;
  }
}

a {
  text-decoration: none;
  color: white;
}

input,
button,
textarea,
select {
  font: inherit;
}

.page-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  padding: 4rem 0rem;

  @media (max-width: 480px) {
    padding: 1rem 0rem;
  }
}

// Scrollbar styling

::-webkit-scrollbar {
  width: 1em;
}

::-webkit-scrollbar-track {
  background: $black;
}

::-webkit-scrollbar-thumb {
  background: $copper-red;
  border: 0.1em solid hsl(14, 62%, 10%);
  border-radius: 100vw;
}

::-webkit-scrollbar:horizontal {
  height: 0;
  width: 0;
  display: none;
}
