@import "../index.scss";

.spartips-page {
  padding: 4rem;
  background: $background-colours;
  position: relative;
  overflow: hidden;

  @media (max-width: 480px) {
    padding: 4rem 1.5rem;
  }

  h1 {
    margin-bottom: 2rem;
  }

  .spartips-container {
    display: flex;
    flex-wrap: wrap;

    .spartipsItem-container {
      display: flex;
      flex-direction: row;
      z-index: 1;
      width: 100%;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      margin-bottom: 2rem;
      border-radius: 5px;
      background-color: white;

      @media (max-width: 750px) {
        flex-direction: column;
      }
      .text-container {
        padding: 1.2rem;

        h2 {
          font-size: 20px;
          margin-top: 0.5rem;
          color: $copper-red;
        }
        p {
          font-size: 14px;

          span {
            font-weight: bold;
            background-color: hsl(14, 100%, 81%);
            border-radius: 3px;
            padding: 2px;
          }
        }
      }

      img {
        width: 200px;
        object-fit: cover;
        object-position: center;
        border-radius: 5px 0px 0px 5px;

        @media (max-width: 750px) {
          width: 100%;
          height: 250px;
          border-radius: 5px 5px 0px 0px;
        }
      }
    }
  }

  .light-bulb {
    width: 600px;
    opacity: 0.9;
    position: absolute;
    top: -17vh;
    right: -330px;
    transform: rotate(140deg);
    filter: blur(0.6px);

    @media (max-width: 480px) {
      top: -3rem;
      width: 500px;
      right: -280px;
    }
  }

  .robot {
    width: 600px;
    position: absolute;
    bottom: 0px;
    left: -320px;
  }
}
