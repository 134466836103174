@import "../../index.scss";

.nav {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 5;
  width: 100vw;
  padding: 1.3rem 4rem 1.3rem 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  font-family: "Comfortaa";

  @media (max-width: 480px) {
    padding: 1.5rem;
  }

  .logo-container {
    .material-icons {
      font-size: 36px;
      color: $copper-red;
      text-shadow: 1px 1px 2px black;
      animation: spin 4s infinite;
    }
  }

  .nav-links-container {
    display: flex;
    align-items: center;
    list-style-type: none;
    a {
      color: rgb(31, 31, 31);
    }
    a:hover {
      border-width: 0px 0px 3px 0px;
      border-style: solid;
      border-color: $copper-red;
    }
    .nav-link {
      margin: 0rem 1rem;
      padding: 0.3rem 0rem;
      text-transform: uppercase;
      font-weight: bold;
    }
    :nth-child(3) {
      margin-right: 0px;
    }
  }
  .nav-btn {
    padding: 5px;
    background: transparent;
    border: none;
    outline: none;
    color: black;
    visibility: hidden;
    opacity: 0;
    font-size: 1.8rem;
  }
  .nav-open-btn {
    position: absolute;
    right: 4rem;

    @media (max-width: 480px) {
      right: 1.5rem;
    }
  }
}

.up {
  background: radial-gradient(
    circle,
    rgb(216, 216, 216) 23%,
    rgba(192, 192, 192, 1) 100%
  );
  box-shadow: 0px 0px 5px black;
  transition-duration: 500ms;
}

.down {
  top: -80px;
}

@media (max-width: 976px) {
  .nav .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  .nav .nav-links-container {
    position: fixed;
    top: -100vh;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $copper-red;
    gap: 1.5rem;
    transition: 1s;
  }

  .nav .responsive-nav {
    transform: translateY(100vh);
    z-index: 10;
    :nth-child(3) {
      margin-right: 1rem;
    }
  }

  .nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  .nav a {
    font-size: 1.5rem;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  26% {
    transform: rotate(200deg);
  }
  46% {
    transform: rotate(190deg);
  }
  76% {
    transform: rotate(370deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
