@import "../../index.scss";

.netowner-section {
  padding: 4rem 6rem;
  background-color: $copper-red;
  display: flex;
  align-items: center;

  @media (max-width: 480px) {
    padding: 3rem 1.5rem 4rem 1.5rem;
  }

  .text-container {
    max-width: 2000px;
    margin: auto;
    font-size: clamp(1.2rem, 1.2vw, 1.4rem);
    width: 75vw;

    p {
      margin-top: 0.7rem;
      margin-bottom: 0.7rem;
      line-height: 1.4;
    }
    p:last-of-type {
      margin-bottom: 0.2rem;
    }

    form {
      .input-row {
        display: flex;
        align-items: center;

        @media (max-width: 800px) {
          flex-direction: column;
          align-items: flex-start;
        }

        label {
          display: inline-block;
          margin-top: 1rem;
          margin-bottom: 1rem;
          margin-right: 0.5rem;
          white-space: nowrap;

          @media (max-width: 480px) {
            white-space: normal;
          }
        }

        .input-and-btn {
          width: 100%;
          display: flex;

          @media (max-width: 480px) {
            display: flex;
            justify-content: center;
            gap: 1rem;
          }

          input,
          textarea {
            height: 100%;
            font-size: 22px;
            display: block;
            border-radius: 14px;
            border: none;
            width: 120px;
            padding: 0.5rem;
            margin: 0rem 0.5rem;

            @media (max-width: 480px) {
              font-size: 18px;
              border-radius: 24px;
              width: 150px;
              padding: 0.8rem;
              margin: auto;
            }
          }

          button {
            font-size: 18px;
            display: block;
            border-radius: 14px;
            height: 100%;
            border: rgb(39, 39, 39);
            background-color: rgb(39, 39, 39);
            color: white;
            padding: 0.7rem;
            margin-inline: 0.5rem;
            width: 120px;

            &:hover {
              background-color: black;
            }
            @media (max-width: 480px) {
              font-size: 18px;
              border-radius: 24px;
              width: 150px;
              padding: 0.84rem;
              margin: auto;
            }
          }
        }
      }
    }
  }
}
