@import "../../index.scss";

.articles-section {
  background-color: $copper-red;
  padding: 4rem 2rem 2rem 2rem;

  .elspartips {
    font-size: 2.5rem;
    margin-top: 0;
    text-align: center;
  }

  .articles-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 2%;

    .card-container {
      max-width: 300px;
      margin: 1rem;
      background-color: rgb(251, 251, 251);
      height: 400px;
      border-radius: 10px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

      img {
        width: 100%;
        max-height: 180px;
        object-fit: cover;
        border-radius: 10px 10px 0 0;
      }
      h2 {
        text-align: center;
        margin: 10px;
        margin-bottom: 0px;

        @media (max-width: 480px) {
          font-size: 1.3rem;
        }
      }
      p {
        padding: 20px;
        margin: 0px;
        font-size: 14px;
        .spartips-link {
          font-weight: bold;
          color: $copper-red-light;
        }
      }
    }
  }
}
footer {
  p {
    text-align: center;
  }
}
