@import "../index.scss";

.sparkalkylator-page {
  background: $background-colours;
  padding: 4rem 4rem;
  min-height: 100vh;
  position: relative;

  @media (max-width: 480px) {
    padding: 4rem 1.5rem;
  }

  p {
    font-size: 18px;
    line-height: 26px;
    position: relative;
    z-index: 1;
  }

  .robot {
    width: 520px;
    position: absolute;
    bottom: 0px;
    right: 0px;
  }
}
