@import "../../index.scss";

.sparkalkylator-form {
  background-color: hsl(0, 0%, 100%);
  padding: 1rem;
  border-radius: 5px;
  position: relative;
  z-index: 1;
  max-width: 760px;

  .material-icons {
    font-size: 16px;
  }

  button {
    height: 100%;
    margin: 0 0.5rem;
    border-radius: 5px;
    padding-top: 0.2rem;
    background-color: rgb(37, 37, 37);
    color: white;
    border: none;
  }
  button:hover {
    background-color: black;
  }

  .btn-add {
    margin-top: 0.2rem;
    margin-bottom: 0.5rem;
    padding-top: 5px;
    padding-right: 7px;
  }
  .btn-calculate {
    height: 36px;
    width: 150px;
    margin: 0.7rem 0.5rem;
    border-radius: 5px;
    border-color: $copper-red;
    background-color: $copper-red;
    color: white;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 8px;
  }
  .btn-calculate:hover {
    background-color: $copper-red-light;
  }
  .sum-all {
    margin: 0.5rem;
    font-size: 30px;
    margin-left: 2rem;
  }

  .sparkalkylator-form-item {
    font-size: 20px;
    margin: 5px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 0.4rem 1rem;
    margin-bottom: 1rem;

    .first-form-row {
      display: flex;
      align-items: center;
      justify-content: space-between;

      select {
        height: 30px;
        border-radius: 5px;
        width: min(26rem, 60%);
      }
      .delete {
        display: flex;
        align-items: center;
        p {
          margin: 0;
        }
      }
    }

    .input-values {
      display: flex;
      flex-wrap: wrap;

      @media (max-width: 600px) {
        width: 250px;
      }

      .tid,
      .effekt {
        margin: 0.2rem 0rem;
      }

      .effekt {
        margin-right: 1rem;
      }

      label {
        color: black;
        display: block;
        margin-bottom: 0.5rem;
      }
      input,
      textarea {
        display: block;
        font-size: 18px;
        padding: 0.2rem;
        width: 100px;
        border-radius: 5px;
        margin: 0rem;
        border: none;
        font-family: "Comfortaa";
        background-color: hsl(14, 66%, 77%);
      }

      p {
        display: inline-block;
        margin: 0px 0px 0px 1rem;
      }
    }
  }
}
